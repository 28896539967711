<template>
      <b-row>
<b-col>
  <b-row>
    <b-col md="6" lg="3">
      <b-form-group
          id="fieldset-1"
          label="Введите артикул вб"
          label-for="input-1"
          valid-feedback=""
          :invalid-feedback="error.message"
          :state="!error.isError"
      >
        <b-form-input id="input-1" v-model="art_wb" :state="!error.isError" trim></b-form-input>
        <b-button block @click.prevent="getFeedbacks()" class="mt-2">Найти</b-button>
      </b-form-group>
    </b-col>
  </b-row>
<b-row>
  <b-col md="3" v-for="(f, indx) of fedbackList" :key="indx">
    <b-card :title="'id: ' + f.wbUserId + ' ' + f.wbUserDetails.name" :sub-title="f.text">
    </b-card>
  </b-col>
</b-row>

</b-col>

      </b-row>

</template>

<style lang="scss">
</style>

<script>
export default {
  name: "feedback-page",
  data: () => ({
    art_wb: "",
    fedbackList: [],
    error: { isError: true, message: "" }
  }),
  async mounted() {

  },
  methods: {
    async getFeedbacks() {
      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/getFeedbacks/${this.art_wb}`,{
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
      .then(resp => resp.json())
      .then(resp => {
        if (!resp.status) {
          this.error = { isError: true, message: resp.msg };
        } else {
          this.error = { isError: false, message: "" };
          this.fedbackList = resp.data
        }
      })
      .catch(err => {
        this.error = { isError: true, message: err };
      })

    }
  },
  computed: {
  },
};
</script>